<template>
  <v-app-bar color="#D9D9D9" elevation="0" class="app-bar">
    <v-app-bar-nav-icon @click="activeDrawer"></v-app-bar-nav-icon>
    <v-btn 
      v-if="comebackRoute"
      icon
      @click="$router.go(-1)"
    >
      <v-icon>
        mdi-arrow-left
      </v-icon>
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {

  name: 'AppBar',

  props: {
    drawer: {
      type: Boolean,
      default: true,
    },
  },

  data(){
    return{
      drawerData: this.drawer,
      comebackRoute: false
    }
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    },

    $route: {
      handler(to) {
        if (to.meta.comeback) {
          this.comebackRoute = true;
          return;
        }
        this.comebackRoute = false;
      },
      deep: true
    }
  },

  methods: {
    activeDrawer() {
      this.drawerData = !this.drawerData;
      this.$emit("setDrawer", this.drawerData);
    },
  },

}
</script>

<style scoped>
.app-bar{
  position: absolute;
  top: 0;
  z-index: 3;
}

</style>