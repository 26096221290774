import System from "@/layouts/Index";
import Inicio from "@/modules/aluno/inicio/routes/index"
import Meta from "@/modules/aluno/meta/routes/index"
import Notificacao from "@/modules/aluno/notificacao/routes/index"
import Perfil from "@/modules/aluno/perfil/routes/index"

import Dashboard from "@/modules/professor/dashboard/routes/index"
import Trilha from "@/modules/professor/trilha/routes/index"
import Agenda from "@/modules/professor/agenda/routes/index"
import Professor from "@/modules/professor/professor/routes/index"
import Aluno from "@/modules/professor/aluno/routes/index"
import Usuario from "@/modules/professor/usuario/routes/index"
import Auth from "@/modules/auth/routes/index"
import TrilhaAluno from "@/modules/aluno/trilha/routes/index"
import Feedback from "@/modules/aluno/feedback/routes/index"


const user = localStorage.getItem("currentUser");
const currentUser = JSON.parse(user);
export default [
  {
    path: "/",
    name: "Home",
    redirect: currentUser?.role_name == "Estudante" ? "/inicio" : "/dashboard",
    component: System,
    meta: { isAuthenticated: true },
    children: [
      ...Inicio,
      ...Meta,
      ...Notificacao,
      ...Perfil,
      ...Dashboard,
      ...Trilha,
      ...Agenda,
      ...Professor,
      ...Aluno,
      ...Usuario,
      ...TrilhaAluno,
      ...Feedback
    ]
  },
  ...Auth,
];