import * as Sentry from "@sentry/vue";

const initSentry = (Vue, env) => {
  const enableSentry = env !== "development";
  const dsn = process.env.VUE_APP_SENTRY_ENDPOINT;
  const tracePropagationTargets = [
    "localhost",
    /^https:\/\/afla\.abacatedigital\.com\/api\/api\/v1/,
  ];

  Sentry.init({
    Vue,
    dsn: dsn,
    environment: env,
    sendClientReports: enableSentry,
    enabled: enableSentry,
    enableTracing: enableSentry,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: tracePropagationTargets,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    trackComponents: true,
    attachStacktrace: true,
  });
};
export default initSentry;
