<template>
  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data(){
    return{
      currentUser: {},
    }
  },

  methods:{
    getRole(){
      const user = localStorage.getItem("currentUser");
      this.currentUser = JSON.parse(user);
      localStorage.setItem('roles', JSON.stringify(this.currentUser?.role_name));
    }
  },

  created(){
    this.getRole()
  }
};
</script>