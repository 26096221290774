import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token: localStorage.getItem("token") || "",
    user: {},

    sessionTime: 0, // Tempo em segundos
    startTime: null,
    currentDay: new Date().toDateString()
  },

  actions: {
    auth({ commit }, token) {
      commit("setToken", token);
    },
    logout({ commit }) {
      commit("setToken", "");
      commit("setUser", "");
      window.localStorage.removeItem("currentUser");
    },
    userData({ commit }, user) {
      commit("setUser", user);
    },

    initializeSession({ commit }) {
      const storedSessionTime = localStorage.getItem('sessionTime');
      const storedStartTime = localStorage.getItem('startTime');
      const storedDay = localStorage.getItem('currentDay');
      const currentDay = new Date().toDateString();

      if (storedDay === currentDay) {
        commit('setSessionTime', parseInt(storedSessionTime, 10) || 0);
        commit('setStartTime', parseInt(storedStartTime, 10) || Date.now());
      } else {
        commit('setSessionTime', 0);
        commit('setStartTime', Date.now());
      }
      commit('setCurrentDay', currentDay);
    },
    updateSessionTime({ state, commit }) {
      const currentTime = Date.now();
      const newSessionTime = state.sessionTime + Math.floor((currentTime - state.startTime) / 1000);
      commit('setSessionTime', newSessionTime);
      commit('setStartTime', currentTime);
      localStorage.setItem('sessionTime', newSessionTime);
      localStorage.setItem('startTime', currentTime);
      localStorage.setItem('currentDay', state.currentDay);
    }
  },

  mutations: {
    setToken: (state, payload) => {
      state.token = payload;
      localStorage.setItem("token", payload);
    },
    setUser: (state, payload) => {
      state.user = payload;
    },

    setSessionTime(state, time) {
      state.sessionTime = time;
    },
    setStartTime(state, time) {
      state.startTime = time;
    },
    setCurrentDay(state, day) {
      state.currentDay = day;
    }
  },
  getters: {
    isLogged: (state) => {
      if (state.token != "") return true;
      return false;
    },
    getToken: (state) => state.token,
    getUser: (state) => {
      if (state.user != {}) return state.user;
      return "";
    },
  },
});