import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store";
import swal from "./plugins/swal";
import initSentry from "./plugins/sentry";
import { VueMaskDirective } from "v-mask";
import canDirective from "./util/directives/can";

import VueQuillEditor from "vue-quill-editor";
import Toast from "vue-toastification";
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";

import "./registerServiceWorker";

import "vue-toastification/dist/index.css";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

const env = process.env.NODE_ENV;

Vue.config.productionTip = false;

Vue.use(canDirective);
Vue.directive("mask", VueMaskDirective);
Vue.use(VueQuillEditor);
Vue.use(Toast, {});
HighchartsNoData(Highcharts);
Vue.use(HighchartsVue, { Highcharts });
initSentry(Vue, env);

new Vue({
  vuetify,
  router,
  store,
  swal,
  render: (h) => h(App),
  created() {
    this.$store.dispatch("initializeSession");
    setInterval(() => {
      this.$store.dispatch("updateSessionTime");
    }, 60000); // Atualizar a cada minuto
  },
}).$mount("#app");
