export default [
	// {
	// 	path: "/tracks",
	// 	name: "Tracks",
	// 	component: () => import("../views/Tracks"),
	// 	meta: { isAuthenticated: true }
	// },
	{
		path: "/track-selected/:idTrilha",
		name: "TrackSelected",
		component: () => import("../views/Index"),
		meta: { 
			isAuthenticated: true,
			comeback: true, 
		}
	},
	{
		path: "/module-study/:idModulo/topic-study/:idTopico/description",
		name: "TopicStudyDescription",
		component: () => import("../views/TopicStudyDescription"),
		meta: { 
			isAuthenticated: true,
			comeback: true, 
		}
	},
	{
		path: "/module-study/:idModulo/topic-study/:idTopico",
		name: "TopicStudy",
		component: () => import("../views/TopicStudy"),
		children: [
			{
				path: "imagem/:idFilenameImage",
				name: "TopicStudyImage",
				component: () => import("../views/FileImage"),
				meta: { isAuthenticated: true }
			},
			{
				path: "video/:idFilenameVideo",
				name: "TopicStudyVideo",
				component: () => import("../views/FileVideo"),
				meta: { isAuthenticated: true }
			},
			{
				path: "html/:idFilenameHtml",
				name: "TopicStudyHtml",
				component: () => import("../views/FileHtml"),
				meta: { isAuthenticated: true }
			},
			{
				path: "pdf/:idFilenamePdf",
				name: "TopicStudyPdf",
				component: () => import("../views/FilePdf"),
				meta: { isAuthenticated: true }
			},
			{
				path: "objeto_3d/:idFilenamePng",
				name: "TopicStudyObject3d",
				component: () => import("../views/FileObjeto3d"),
				meta: { isAuthenticated: true }
			},
		]
	},
	{
		path: "/module-study/:idModulo/topic-study/:idTopico/questions",
		name: "TrackQuestions",
		component: () => import("../views/Questions"),
		meta: { isAuthenticated: true },
	},
];
  