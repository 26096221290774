export default [
	{
		path: "/feedback-topic-questions",
		name: "FeedbackTopicQuestions",
		component: () => import("../views/FeedbackTopicQuestions"),
		meta: { isAuthenticated: true }
	},
	{
		path: "/feedback-topic",
		name: "FeedbackTopic",
		component: () => import("../views/FeedbackTopic"),
		meta: { isAuthenticated: true }
	},
	{
		path: "/feedback-module",
		name: "FeedbackModule",
		component: () => import("../views/FeedbackModule"),
		meta: { isAuthenticated: true }
	},
];
  