export default [
	{
		path: "/login",
		name: "Login",
		component: () => import("@/layouts/auth/Login.vue"),
	},
	{
		path: "/login/:token",
		name: "Auth",
		component: () => import("@/layouts/auth/Login.vue"),
	},
	{
		path: "/register",
		name: "Register",
		component: () => import("@/layouts/auth/Register.vue"),
	},
	{
		path: "/forgot-my-password",
		name: "RedefinirSenha",
		component: () => import("@/layouts/auth/ForgotMyPassword.vue"),
	},
	{
		path: "/reset-password",
		name: "ResetPassword",
		component: () => import("@/layouts/auth/ResetPassword.vue"),
	},
];