<template>
  <div>
    <v-navigation-drawer app v-model="drawerData" @input="updateDrawer">
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img width="100" :src="require('@/assets/img/revisanatomy.png')"></v-img>
          </v-list-item-content>
        </v-list-item>
      </v-list>

      <v-divider></v-divider>
      <v-list nav dense>
        <v-list-item-group>
          <div v-for="(item, x) in itemsMenu" :key="x">
            <v-list v-if="!item.hasOwnProperty('children')" v-can:roles="item.role">
              <router-link :to="item.route">
                <v-list-item>
                  <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>{{ item.text }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </router-link>
            </v-list>
          </div>

          <v-list>
            <v-list-item @click="logout()">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Sair</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-list-item-group>
      </v-list>
      <div class="logo">
        <v-img width="70" :src="require('@/assets/img/abacate-logo.png')"></v-img>
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
export default {
  props: {
    drawer: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      drawerData: false,
      itemsMenu: [
        {
          text: "Home",
          icon: "mdi-home",
          route: "/inicio",
          role: ["Estudante"]
        },
        {
          text: "Dashboard",
          icon: "mdi-view-dashboard",
          route: "/dashboard",
          role: ["Professor"]
        },
        {
          text: "Trihas",
          icon: "mdi-file-certificate",
          route: "/trilha",
          role: ["Professor"]
        },
        // {
        //   text: "Agenda",
        //   icon: "mdi-file-document-multiple",
        //   route: "/agenda",
        //   role: ["Professor"]
        // },
        {
          text: "Alunos",
          icon: "mdi-account-multiple",
          route: "/aluno",
          role: ["Professor"]
        },
        {
          text: "Professores",
          icon: "mdi-school",
          route: "/Professor",
          role: ["Admin"]
        },

        {
          text: "Usuários",
          icon: "mdi-account",
          route: "/usuario",
          role: ["Admin"]
        }
      ]
    };
  },

  methods: {
    updateDrawer(value) {
      this.drawerData = value;
      this.$emit("setDrawerResponsiveness", value);
    },
    logout() {
      this.$store.dispatch("logout");
      this.$router.push("/login");
    }
  },

  watch: {
    drawer(value) {
      this.drawerData = value;
    }
  }
};
</script>

<style scoped>
a {
  text-decoration: none;
}

.logo {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
